export enum LocalStorageEnum {
  token = '_t',
  token_expires_at = '_tea',
  user_id = '_u',
  user_profile = '_uf',
  user_firstname = '_ufn',
  user_lastname = '_uln',
  user_role = '_ur',
  user_division_name = '_udn',
  user_division_id = '_udi',
  refresh_token = '_rft',
  lang = 'lang',
}
