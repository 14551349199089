import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from '../shared/components/custom-snack-bar/custom-snack-bar.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MessageSnackbarService {
  constructor(
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService
  ) {}

  showError(error?: any, duration?: number): void {
    let message = `${this._translateService.instant('error')}:\n`;
    let messages = [];
    if (Array.isArray(error.message)) {
      messages = error.message;
    } else if (typeof error === 'string') {
      messages.push(error);
    } else {
      messages.push(error.message);
    }
    if (messages.length !== 0) {
      messages.forEach((e: any, index: number) => {
        if (index !== messages.length - 1) {
          message += '-' + e + '\n';
        } else {
          message += e;
        }
      });
      this._snackbar.openFromComponent(CustomSnackBarComponent, {
        data: message,
        duration: duration ? duration : 10000,
        panelClass: ['error-snack-bar'],
      });
    } else {
      this._snackbar.openFromComponent(CustomSnackBarComponent, {
        data: 'Operation error!',
        duration: duration ? duration : 10000,
        panelClass: ['error-snack-bar'],
      });
    }
  }

  showSuccess(message: string, duration?: number): void {
    this._snackbar.openFromComponent(CustomSnackBarComponent, {
      data: message,
      duration: duration ? duration : 10000,
      panelClass: 'success-snack-bar',
    });
  }

  showWarning(message: string, duration?: number): void {
    this._snackbar.openFromComponent(CustomSnackBarComponent, {
      data: message,
      duration: duration ? duration : 10000,
      panelClass: 'warning-snack-bar',
    });
  }
}
